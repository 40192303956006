.nav-container {
    width: 100%;
    max-width: 1000px;
    position: relative;
    margin: 0 auto;
    margin-top: 20px;
}

.router-link {
    text-decoration: none;
    color: inherit;
}

.access-info {
    width: 70%;
    max-width: 700px;
    text-align: center;
    margin: 0 auto;
    padding: 5px;
    margin-bottom: 5px;
    background-color: #0860e4;
    color: white;
}

.option {
    display: inline-block;
    width: 50%;
    height: 60px;
    padding-top: 35px;
    text-align: center;
    font-weight: bold;
    color: #0860e4;
    cursor: pointer;
    font-size: 24px;
}

.n-selected {
    background-color: #0860e4;
    color: white;
    cursor: default;
}

.swap-text {
    text-align: center;
    font-size: 28px;
    color: #b7b7b7;
    margin-top: 8px;
    margin-bottom: 25px;
    position: relative;
    FONT-WEIGHT: 500;
    cursor: default;
    top: 20px;
}

.for-text {
    text-align: center;
    font-size: 28px;
    color: #b7b7b7;
    margin-top: 15px;
    FONT-WEIGHT: 500;
    cursor: default;
}

.start-token-container {
    width: 100%;
    height: 70px;
    border-top: solid lightgray 1px;
    border-bottom: solid lightgray 1px;
    position: relative;
    margin-top: 15px;
}

.start-token-info {
    margin: 0 auto;
    margin-top: 10px;
    text-align: center;
    font-size: 35px;
    font-weight: bold;
}

.start-token-input {
    width: 400px;
    height: 33px;
    top: 1px;
    margin-left: -2px;
    position: relative;
    text-align: center;
    font-size: 35px;
    font-weight: bold;
    color: black;
    outline: none;
    border: none;
}

.start-ETH {
    position: absolute;
    right: 10%;
    top: 10px;
    color: black;
}

.end-token-container {
    width: 100%;
    height: 90px;
    border-top: solid lightgray 1px;
    border-bottom: solid lightgray 1px;
    position: relative;
    margin-top: 15px;
    color: black;
}

.end-token-address-container {
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: transparent;
    border: none;
    color: white;
    text-align: center;
    outline: none;
    font-weight: 300;

    
}

.end-token-address-container::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
  }

.end-token-info {
    margin: 0 auto;
    margin-top: 10px;
    text-align: center;
    font-size: 35px;
    font-weight: bold;
}

.end-token-input-container {
    width: 80%;
    max-width: 700px;
    height: 30px;
    background-color: #697a93;
    margin: 0 auto;
    margin-top: -10px;
    position: relative;
    border-radius: 0 0 5px 5px;
}

.profit-loss-text {
    text-align: center;
    font-size: 28px;
    color: #b7b7b7;
    margin-top: 15px;
    FONT-WEIGHT: 500;
    cursor: default;
}

.profit-loss-container {
    width: 100%;
    height: 70px;
    border-top: solid lightgray 1px;
    border-bottom: solid lightgray 1px;
    position: relative;
    margin-top: 15px;
}

.profit-loss-info {
    margin: 0 auto;
    margin-top: 14px;
    text-align: center;
    font-size: 25px;
    font-weight: 400;
    color: #43b335;
}

.profit-loss-input {
    width: 70%;
    height: 35px;
    text-align: center;
    font-size: 24px;
    outline: none;
    border: none;
    color: #0fb20f;
}


.send-it {
    width: 70%;
    max-width: 450px;
    padding: 20px;
    color: white;
    font-size: 21px;
    font-weight: bold;
    background-color: #0860e4;
    text-align: center;
    margin: 0 auto;
    margin-top: 10px;
    cursor: pointer;
}

.bid-container {
    text-align: center;
    margin-top: 20px;
}

.bid {
    min-width: 320px;
    width: 30%;
    max-width: 600px;
    height: 140px;
    border: solid 1px #0860e4;
    margin-right: 5px;
    display: inline-block;
    text-align: left;
    overflow: hidden;
    position: relative;
    padding: 8px;
}

.bid-token {
    font-weight: bold;
    color: #0860e4;
}

.call-button {
    background-color: #0860e4;
    color: white;
    padding: 5px;
    width: 50px;
    text-align: center;
    border-radius: 4px;
    position: absolute;
    bottom: 3px;
    cursor: pointer;
    margin: 0 auto;
    right: 0;
    left: 0;
}

.bold {
    font-weight: bold;
}

.in-profit {
    color: #0fb20f;
}

.in-loss {
    color: rgb(173, 27, 27);
}

.call-low-button {
    background-color: #505050;
    color: white;
    padding: 5px;
    width: 145px;
    text-align: center;
    border-radius: 4px;
    position: absolute;
    bottom: 3px;
    cursor: not-allowed;
    margin: 0 auto;
    right: 0;
    left: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.call-info {
    border: solid #0860e4 1px;
    color: #0860e4;
    padding: 5px;
    width: 70%;
    max-width: 750px;
    margin: 0 auto;
    margin-top: 15px;
    text-align: center;
}